<template>
  <div>
    <el-row :gutter="20">

      <el-col :span="22">
        <div class="grid-content bg-purple-light">
          <div class="search" style="height: 130px">
            <el-form size="small" :inline="true" :model="formInline" ref="formInline" class="demo-form-inline">
              <el-form-item label="订单号" prop="order_no">
                <el-input v-model="formInline.order_no" placeholder="订单号"></el-input>
              </el-form-item>
              <el-form-item label="发薪单名称" prop="notes">
                <el-input v-model="formInline.notes" placeholder="发薪单名称"></el-input>
              </el-form-item>
              <el-form-item label="项目名称" prop="project_name">
                <el-input v-model="formInline.project_name" placeholder="项目名称"></el-input>
              </el-form-item>
              <el-form-item label="商户号" prop="sub_mch_id">
                <el-input v-model="formInline.sub_mch_id" placeholder="商户号"></el-input>
              </el-form-item>
              <el-form-item label="发薪人" prop="wx_mch_name">
                <el-input v-model="formInline.wx_mch_name" placeholder="发薪人"></el-input>
              </el-form-item>
              <el-form-item label="状态" prop="status">
                <el-select v-model="formInline.status">
                  <el-option :value="0" label="全部"></el-option>
                  <el-option :value="1" label="初始化"></el-option>
                  <el-option :value="2" label="处理中"></el-option>
                  <el-option :value="5" label="发薪中"></el-option>
                  <el-option :value="6" label="已完成"></el-option>
                  <el-option :value="7" label="发薪错误"></el-option>
                  <el-option :value="8" label="部分完成"></el-option>

                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="getList()">查询</el-button>
                <el-button @click="reset('formInline')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
<!--    <el-button type="primary" @click="add" size="small">添加</el-button>-->
    <el-table ref="listTable" v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column align="center" prop="id" label="序号" sortable width="80"></el-table-column>
      <el-table-column align="center" prop="order_no" label="发薪单编码"></el-table-column>
      <el-table-column align="center" prop="notes" label="发薪单名称"></el-table-column>
      <el-table-column align="center" label="项目名称">
        <template slot-scope="scope">
          {{scope.row.wx_project.name}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="amount" label="金额"></el-table-column>
      <el-table-column align="center" label="发薪人">
        <template slot-scope="scope">
          {{scope.row.wx_mch?scope.row.wx_mch.name:''}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status===6">已完成</el-tag>
          <el-tag type="success" v-else-if="scope.row.status===2">处理中</el-tag>
          <el-tag type="success" v-else-if="scope.row.status===8">部分完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.status===7">发薪错误</el-tag>
          <el-tag type="warning" v-else-if="scope.row.status===5">发薪中</el-tag>
          <el-tag type="warning" v-else-if="scope.row.status===4">已受理</el-tag>
          <el-tag type="success" v-else-if="scope.row.status===1">初始化</el-tag>
          <el-tag v-else-if="scope.row.status===6">已完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="发薪时间"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="260">
        <template slot-scope="scope">
<!--          <el-button :disabled="scope.row.status!==1 || scope.row.wx_order_detail_count===0" @click="approval(scope.row)" type="text" size="small">提交审批</el-button>-->
          <el-button  @click="detail(scope.row.order_no,scope.$index)" type="text" size="small">发薪人员</el-button>
<!--          <el-button :disabled="scope.row.status===6||scope.row.status===2||scope.row.status===5" @click="pay(scope.row.id,scope.row.order_no)" type="text" size="small">发薪</el-button>-->
<!--          <el-button  @click="fam(scope.row.img)" type="text" size="small">发薪码</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @prev-click="prev" @next-click="next" @current-change="CurrentChange" layout="total,prev, pager, next" :page-size="pageSize" :total="total"></el-pagination>
    <el-dialog title="发薪人员" :destroy-on-close="true" width="75%" :show-clos="false" :close-on-click-modal="false" :close-on-press-escape="false"  :visible.sync="dialogTableVisible">
      <div style="display: flex;gap: 10px" v-if="dialogTableVisible">
        <el-button :disabled="data[orderIndex].status === 6 || data[orderIndex].status === 2 || data[orderIndex].status === 5" type="primary" @click="exportToExcel(gridData)" size="mini">导出</el-button>
<!--        <el-button :disabled="data[orderIndex].status === 6 || data[orderIndex].status === 2 || data[orderIndex].status === 5" size="mini" type="primary"  @click="dao">导入</el-button>-->
      </div>
      <el-table v-loading="staffLoading" :data="gridData" max-height="500"  @cell-dblclick="cellEdit">
        <el-table-column align="center" property="out_detail_no" label="订单号"></el-table-column>
        <el-table-column align="center" property="user_name" label="姓名" width="200"></el-table-column>
        <el-table-column align="center" property="amount" label="支付金额">
          <template slot-scope="scope">
            <div v-if="payId===scope.row.id">
              <el-input-number :precision="2" v-if="isShowPanel" v-model="amount" :min="0.5" label="支付金额"></el-input-number>
            </div>
            <span v-else-if="!isShowPanel">
              {{scope.row.amount}}
            </span>
            <span v-else>
              {{scope.row.amount}}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="created_at" label="添加时间"></el-table-column>
        <el-table-column align="center" prop="created_at" label="状态">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.status===1">初始态</el-tag>
            <el-tag type="warning" v-else-if="scope.row.status===2">发薪中</el-tag>
            <el-tag type="danger" v-else-if="scope.row.status===3">发薪失败</el-tag>
            <el-tag v-else-if="scope.row.status===4">发薪成功</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" property="notes" label="备注" width="200"></el-table-column>
        <el-table-column align="center" prop="created_at" label="操作">
          <template slot-scope="scope">
            <div v-if="payId===scope.row.id">
              <el-button type="text" @click="save(scope.$index)" size="small"> 提交</el-button>
            </div>
            <el-popconfirm  v-else-if="scope.row.status==1" @confirm="deletes(scope.row.id,scope.row.order_no)" title="确定要删除吗？">
              <el-button slot="reference" type="text" size="small"> 删除</el-button>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="支付密码" :before-close="handleClose" :destroy-on-close="true" :show-clos="false" :close-on-click-modal="false" :close-on-press-escape="false" width="500px" :visible.sync="payVisible">
      <div v-loading="payLoading"   style="display: flex;justify-content: space-between;margin-bottom: 10px">
        <div class="extend-title">
          支付账户
        </div>
        <div >
          <div style="cursor:pointer;" @click="getMch">
            {{mchDetail.name}}<i class="el-icon-arrow-right"></i>
          </div>
          <div :style="{display:mchLists?'block':'none'}" class="mchList">
            <div v-for="item in mchList">
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <el-input  size="small" v-model="payPwd" type="password" placeholder="支付密码"></el-input>
      <template #footer>
      <span class="dialog-footer">
             <el-button size="small" type="primary" @click="pays()">支付</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog title="商户列表" width="500px" :visible.sync="mchLists">
      <div v-for="item in mchList" :class="[item.id==mchDetail.id?'mchListCheck':'','mchList']"  @click="mchDetailClick(item)">
        <div>{{item.name}}</div>
        <div>{{item.available_amount?item.available_amount:'0'}}</div>
      </div>
    </el-dialog>

    <el-dialog title="创建发薪单"  :destroy-on-close="true" width="30%" :show-clos="false" :close-on-click-modal="false" :close-on-press-escape="false"  :visible.sync="addVisible">

      <el-form :rules="rules" v-loading="addLoading"  label-width="100px" size="small" :inline="true" :model="fxdData" ref="fxdData" class="demo-form-inline">
        <el-form-item label="发薪单名称" prop="notes">
          <el-input v-model="fxdData.notes" placeholder="请输入发薪单名称"></el-input>
        </el-form-item>
        <el-form-item label="发薪单备注" prop="remark">
          <el-input v-model="fxdData.remark" placeholder="请输入发薪单备注"></el-input>
        </el-form-item>
<!--        <el-form-item label="项目" prop="wx_project_id">-->
<!--          <el-select size="small" @change="projectChange" v-model="fxdData.project" placeholder="请选择">-->
<!--            <el-option-->
<!--                v-for="item in project"-->
<!--                :label="item.name"-->
<!--                :value="item">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->

        <el-form-item label="用工类型" prop="employment_type">
          <el-select size="small" v-model="fxdData.employment_type" placeholder="请选择用工类型">
            <el-option
                v-for="item in employment_type"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用工场景" prop="employment_scene">
          <el-select size="small" v-model="fxdData.employment_scene" placeholder="请选择用工类型">
            <el-option
                v-for="item in employment_scene"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="employment_scene">
          <el-radio-group v-model="fxdData.gz_type">
            <el-radio :label="1">工资</el-radio>
            <el-radio :label="2">预支</el-radio>
          </el-radio-group>
        </el-form-item>
        <br/>
        <el-form-item label-width="200px" label="员工扫码后自动加入该项目" prop="is_join_project">
          <el-radio-group v-model="fxdData.is_join_project">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button :loading="addLoading" size="small" type="primary" @click="adds()">创建</el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog title="提交审批" width="30%" :visible.sync="approvalVisible">
      <el-descriptions title="" :column="2">
        <el-descriptions-item label="发薪人数">{{ detailData.wx_order_detail_count }}</el-descriptions-item>
        <el-descriptions-item label="发薪金额">{{ detailData.amount }}</el-descriptions-item>
      </el-descriptions>
      <el-form v-loading="approvalLoading" label-position="left" label-width="95px" size="small" :inline="true" :model="approvalData" ref="approvalData">
        <el-form-item label="选择审批人" prop="j_admin_id" :rules="[
                { required: true, message: '请选择审批人'},
              ]">
          <el-select size="small" v-model="approvalData.j_admin_id" placeholder="请选择审批人" >
            <el-option
                v-for="item in approvalUser"
                :label="item.name"
                :value="item.admin_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="notes">
          <el-input type="textarea" :rows="5" show-word-limit v-model="approvalData.notes" maxlength="150" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button :loading="approvalLoading" size="small" type="primary" @click="approvalSave('approvalData')">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="导入" :destroy-on-close="true" width="50%" :show-clos="false" :close-on-click-modal="false" :close-on-press-escape="false"  :visible.sync="daoVisible">
      <el-form size="small" :inline="true" :model="formSeach" ref="formInline" class="demo-form-inline">
        <el-form-item label="姓名" prop="user_name">
          <el-input v-model="formSeach.user_name" placeholder="申请人昵称"></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="id_card_number">
          <el-input v-model="formSeach.id_card_number" placeholder="身份证"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="dao()">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="listTable" @selection-change="handleSelectionChange" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" :data="mchUserData"  style="width: 100%;">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" prop="id" label="序号" sortable width="80"></el-table-column>
        <el-table-column align="center" prop="user_name" label="姓名"></el-table-column>
        <el-table-column align="center" prop="id_card_number" label="身份证"></el-table-column>

      </el-table>
      <template #footer>
        <span class="dialog-footer">
               <el-button size="small" type="primary" @click="daoru()">导入</el-button>
        </span>
      </template>
    </el-dialog>
    <el-image
        ref="preview"
        style="display: none"
        :src="url+famImg"
        :preview-src-list="[url+famImg]">
    </el-image>
  </div>
</template>

<script>
import {request} from "@/library/request.js";
import { utils, writeFileXLSX,writeFile } from 'xlsx'

export default {
  data(){
    return{
      data:[],
      total:0,
      gridData:[],
      gridTotal:0,
      amount:0,
      addLoading:false,
      projectIndex:0,
      gridPageSize:15,
      gridCurrentPage:0,
      project:[],
      pageSize:15,
      currentPage:0,
      loading: false,
      payId:"",
      dialogTableVisible:false,
      payVisible:false,
      payPwd:"",
      mchDetail:{},
      mchLists:false,
      mchList:[],
      famImg:"",
      url:this.common.url,
      payLoading:false,
      isShowPanel: false,
      orderIndex:"",
      formInline:{
        sub_mch_id:"",
        status:0,
        order_no:"",
        project_name:"",
        wx_mch_name:"",
      },
      addVisible:false,
      project_id:"",
      fxdData:{
        notes:"",
        gz_type:1,
        remark:"",
        wx_project_id:"",
        project_name:"",
        employment_scene:"",
        employment_type:"",
        is_join_project:"0"
      },
      rules: {
        notes:[
          { required: true, message: '请输入发薪单名称', trigger: 'blur' },
          {  max: 32, message: '长度最大32位', trigger: 'blur' }
        ],
        wx_project_id:[
          { required: true, message: '请选择项目', trigger: 'change' },
        ],
        employment_scene:[
          { required: true, message: '请选择用工类型', trigger: 'change' },
        ],
        employment_type:[
          { required: true, message: '请选择用工场景', trigger: 'change' },
        ]
      },
      employment_type: [{"value":"LONG_TERM_EMPLOYMENT",'text':"长期用工"},{"value":"SHORT_TERM_EMPLOYMENT",'text':"短期用工"},{"value":"COOPERATION_EMPLOYMENT",'text':"合作关系"}],
      employment_scene: [{"value":"LOGISTICS",'text':"物流"},
        {"value":"MANUFACTURING",'text':"制造业"},
        {"value":"HOTEL",'text':"酒店"},
        {"value":"CATERING",'text':"餐饮业"},
        {"value":"EVENT",'text':"活动促销"},
        {"value":"RETAIL",'text':"零售"},
        {"value":"OTHERS",'text':"其他"}],
      detailData:"",
      approvalUser:[],
      approvalData:{
        notes:"",
        j_admin_id:"",
        wx_order_no:"",
      },
      approvalLoading:false,
      approvalVisible:false,
      daoData:[],
      mchUserData:[],
      formSeach:{
        user_name:"",
        id_card_number:""
      },
      daoVisible:false,
      staffLoading:false
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.daoData = val
    },
    dao(){
      return request({
        url:'mchUser/list',
        method:"get",
        params:this.formSeach
      }).then(response  => {
        this.daoVisible = true
        this.mchUserData = response.data
      }).then(err => {
        if(err){
          this.loading = false
          this.$Loading.error();
          this.$Message.error("系统异常，请稍后重试")
        }
      })
    },
    daoru(){
      console.log(this.orderIndex)
      return request({
        url:'wxorder/imports',
        method:"post",
        data: { order_no:this.data[this.orderIndex].order_no,project_id:this.data[this.orderIndex].wx_project_id,mchUser:this.daoData}
      }).then(response  => {
        if (response.code == 200){
          this.daoVisible = false;
          this.$message.success("导入成功");
          this.detail(this.order_no,this.orderIndex)
        }else{
          this.$message.error("导入失败");

        }
      }).then(err => {
        if(err){
          this.loading = false
          this.$Loading.error();
          this.$Message.error("系统异常，请稍后重试")
        }
      })
    },
    getList(){
      this.loading = true
      this.$Loading.start();
      this.formInline.limit = this.pageSize
      this.formInline.page = this.currentPage
      return request({
        url:'wxorder/list',
        method:"get",
        params:this.formInline
      }).then(response  => {
        this.$Loading.finish();
        this.loading = false
        this.data = response.data.data
        this.total = response.data.total
      }).then(err => {
        if(err){
          this.loading = false
          this.$Loading.error();
          this.$Message.error("系统异常，请稍后重试")
        }
      })
    },
    handleClose(done) {
      this.payPwd = ""
      done();
    },
    prev(value){
      this.currentPage = value
      this.getList()
    },
    next(value){
      this.currentPage = value
      this.getList()
    },
    add(){
      this.addVisible = true
    },
    projectChange(res){
      this.fxdData.project_name = res.name
      this.fxdData.wx_project_id = res.id
    },
    adds(){
      //
      this.$refs["fxdData"].validate((valid) => {
        if (valid) {
          this.addLoading = true
          request({url:'wxorder/create',method:"post",data:this.fxdData}).then(res=>{
            this.addLoading = false
            if(res.code === 200){
              this.$message.success("创建成功")
              this.addVisible = false;
              this.fxdData = {}
            }else{
              this.$message.error(res.message)
            }
          })
        } else {
          this.addLoading = false
          return false;
        }
      });
    },
    deletes(id,order_no){
      this.staffLoading = true
      return request({
        url:'wxorder/delete/'+id,
        method:"post",
      }).then(response  => {
        this.$Loading.finish();
        // this.loading = false
        this.staffLoading = true
        this.detail(order_no,this.orderIndex)
        this.$message.success("删除成功")
      }).then(err => {
        if(err){
          this.$Loading.error();
          this.staffLoading = false
          this.$message.error("系统异常，请稍后重试")
        }
      })
    },
    cellEdit(row, column) {
      if (column.label==="支付金额" && row.status===1){
        this.amount = row.amount
        this.payId = row.id
        console.log(this.payId)
        this.isShowPanel = true
      }
    },
    CurrentChange(value){
      this.currentPage = value
      this.getList()
    },
    reset(formName){
      this.$refs[formName].resetFields();
      this.getList()
    },
    approval(data){
      this.detailData = data
      this.approvalVisible = true
      return request({
        url:'shenRoleUser',
        method:"get",
      }).then(response  => {
        console.log(response)
        this.approvalUser = response.data
      })
    },
    approvalSave(formName){
      this.approvalData.wx_order_no = this.detailData.order_no
      this.$refs[formName].validate((valid) => {
        this.approvalLoading = true
        if (valid) {
          return request({
            url:'examine_and_approve/create',
            method:"post",
            data:this.approvalData
          }).then(response  => {
            this.approvalLoading = false

            if (response.code===200){
              // this.approvalVisible = false
              this.$message.success(response.msg)
            }else{
              this.$message.error(response.msg)
            }
          })
        }else{
          this.approvalLoading = false
          return false;
        }
      })
    },
    detail(order_no,index){
      this.order_no = order_no
      this.orderIndex = index
      this.staffLoading = true
      return request({
        url:'wxorder/detail',
        method:"get",
        params: { order_no:order_no}
      }).then(response  => {
        this.staffLoading = false
        this.dialogTableVisible = true
        this.gridData = response.data.wx_order_detail
      }).then(err => {
        if(err){
          this.$Loading.error();
          this.staffLoading = false
          this.$message.error("系统异常，请稍后重试")
        }
      })
    },
    mchDetailClick(val){
      this.mchDetail = val
      this.mchLists=false
    },
    pay(val,order_no){
      this.payVisible = true
      this.payId = val
      this.order_no = order_no
      this.getMchDetail()
    },
    save(index){
      request({url:"wxorder/edit",method:"post",data:{id:this.payId,amount:this.amount}}).then(res=>{
        if (res.code === 200){
          this.gridData[index].amount = this.amount
          this.amount=0;
          this.payId="";
          this.isShowPanel=false;
          // this.detail(res.data.order_no);
        }else{
          this.$message.error(res.msg)
        }
      })
    },

    imports(){

    },
    dow(){
      var data = this.data[this.orderIndex];


      const wb = utils.book_new();

      // 创建一个工作表
      const ws = utils.aoa_to_sheet([
        ['发薪单编码', data['order_no']],
        ['发薪单名称', data['notes']],
        ['人员姓名',"人员手机号","人员价格","人员身份证","备注"],
        // 更多数据行
      ]);
      ws['!cols'] = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 25 }, { wch: 15 }];
      // 将工作表添加到工作簿
      utils.book_append_sheet(wb, ws, 'Sheet1');

      // 将工作簿保存为文件
      writeFile(wb, '发薪人员导入模板.xlsx');
    },
    exportToExcel(data) {
      const tableHeader = ['out_detail_no', 'user_name', 'phone', 'id_card_number','notes','status']
      // 处理数据保证
      const sheetData = data.map((item) => {
        if (item.status === 1){
          item.status = "待发薪"
        }else if (item.status === 2){
          item.status = "发薪中"
        }else if (item.status === 3){
          item.status = "发薪失败"
        }
        else if (item.status === 4){
          item.status = "发薪成功"
        }
        const _item = {}
        tableHeader.forEach(key => {
          _item[key] = item[key]
        })
        return _item
      })
      // 创建一个工作表
      const worksheet = utils.json_to_sheet(sheetData)
      // 创建一个新的工作簿
      const workbook = utils.book_new()
      // 把工作表添加到工作簿
      utils.book_append_sheet(workbook, worksheet, 'Data')
      // 改写表头
      let wscols = [    // 每列不同宽度px
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 30 },
        { wch: 20 },
      ]
      worksheet["!cols"] = wscols;
      utils.sheet_add_aoa(worksheet, [['编码', '姓名', '联系电话','身份证号码','备注', '状态']], { origin: 'A1' })
      writeFileXLSX(workbook, 'SheetJSVueAoO.xlsx')
    },
    getMchDetail(){
      this.payLoading = true
      request({url:"getMchDetail",method:"get"}).then(res=>{
        this.payLoading = false
        if (res.code === 200 ){
          this.mchDetail = res.data
        }else{
          this.$message.error(res.msg);
        }
      }).then(err => {
        this.payLoading = false
      })
    },
    fam(val){
      this.famImg = val
      this.$refs.preview.clickHandler()
    },
    getMch(){
      this.mchLists = true
      request({url:"wxMch/list?limit=1500000",method:"get"}).then(res=>{
        this.mchList = res.data.data
      })
    },
    pays(){
      var that = this
      request({url:"validatorPassword",method:"POST",data:{password:this.payPwd}}).then(res=>{
        if(res.code===200){
          request({url:"wxorder/pay",method:"POST",data:{id:that.payId,"mch_id":that.mchDetail.id,"order_no":that.order_no}}).then(res=>{
            if (res.code !== 200){
              that.$message.error(res.msg);
            }else{
              that.$message.success(res.msg);
              that.payVisible = false
            }
            that.getList()
          })
        }
      })
    },
    query(id){
      return request({
        url:'wxOrder/query',
        method:"post",
        data: { id:id}
      }).then(response  => {
        if (response.code !== 200 ){
          this.$Message.error(response.msg)
        }else{
          this.getList()
        }
      }).then(err => {
        if(err){
          this.loading = false
          this.$Loading.error();
          this.$Message.error("系统异常，请稍后重试")
        }
      })
    }
  },
  mounted() {
    this.getList()
    // return request({
    //   url:'project/list',
    //   method:"get",
    //   params: { limit:1000000,page:0}
    // }).then(response  => {
    //   this.project = response.data.data
    //   this.getList()
    // }).then(err => {
    //   if(err){
    //     this.loading = false
    //     this.$Loading.error();
    //     this.$Message.error("系统异常，请稍后重试")
    //   }
    // })
  }
}
</script>

<style scoped>
@import url('/css/app.css');
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.mchList{
  /*height: 100px;*/
  /*max-height: 200px;*/
  /*//display: block;*/
  /*//display: none;*/
  /*border: 1px solid #EBEEF5;*/
//box-shadow: h-shadow v-shadow blur spread color inset;
//box-shadow: 2px 1px 2px #EBEEF5;
  display: flex;
  justify-content: space-between;
  height: 50px;
  border: 1px solid #d5d5d5;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}
.mchListCheck{
  border: 1px solid #44aa75;
}
</style>
<style>
.vertical-center-modal .ivu-modal-body{
  height: 600px;
  overflow-y: scroll;
}
</style>

